// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-event-template-js": () => import("/opt/build/repo/src/templates/EventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/opt/build/repo/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-add-event-add-event-page-js": () => import("/opt/build/repo/src/pages/add-event/AddEventPage.js" /* webpackChunkName: "component---src-pages-add-event-add-event-page-js" */),
  "component---src-pages-add-event-index-js": () => import("/opt/build/repo/src/pages/add-event/index.js" /* webpackChunkName: "component---src-pages-add-event-index-js" */),
  "component---src-pages-contact-contact-us-page-js": () => import("/opt/build/repo/src/pages/contact/ContactUsPage.js" /* webpackChunkName: "component---src-pages-contact-contact-us-page-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-events-js": () => import("/opt/build/repo/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-home-categories-js": () => import("/opt/build/repo/src/pages/home/Categories.js" /* webpackChunkName: "component---src-pages-home-categories-js" */),
  "component---src-pages-home-jumbotron-js": () => import("/opt/build/repo/src/pages/home/Jumbotron.js" /* webpackChunkName: "component---src-pages-home-jumbotron-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partnership-page-index-js": () => import("/opt/build/repo/src/pages/partnership-page/index.js" /* webpackChunkName: "component---src-pages-partnership-page-index-js" */)
}

