/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location }) => {
  const pathname = location.pathname || ''
  const paths = pathname.split('/')

  if (paths[1] === 'events' && paths[2]) {
    const base = `${process.env.GATSBY_FIREBASE_FUNCTION_BASE_URL}/hit`
    fetch(base + '?eventSlug=' + paths[2])
  }
}
